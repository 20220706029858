































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { RawD6School, Api } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'

@Component({
  name: 'LinkD6'
})

export default class LinkD6 extends Mixins(ComponentHelper) {
  @Prop() private schoolId?: number

  schoolsData: RawD6School[] | null = null
  loading: boolean = false

  mounted () {
    this.loadSchools()
  }

  async loadSchools () {
    this.loading = true
    try {
      const resp = await Api.getD6Schools()
      this.schoolsData = resp.schools
    } catch (error: unknown) {
      console.log(error)
    } finally {
      this.loading = false
    }
  }

  async linkSchool (d6Id: string) {
    if (this.schoolId && d6Id) {
      try {
        await Api.linkD6(this.schoolId, parseInt(d6Id))
        this.$emit('close')
      } catch (error: unknown) {
        console.log(error)
      }
    }
  }

  public closeView () {
    this.$emit('close')
  }
}
