






















































































import { Api, TableState, PagedResults, District, request } from '@/edshed-common/api'
import Component from 'vue-class-component'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Mixins, Prop } from 'vue-property-decorator'

@Component({ components: {} })
export default class LinkDistrict extends Mixins(ComponentHelper) {
  @Prop({ required: true }) schoolId!: number

  private filteredDistrictsData: PagedResults<District> = {
    items: [],
    total: 0
  }

  private loading: boolean = false
  private response: string = ''

  private tableState: TableState = {
    page: 1,
    perPage: 20,
    sort: 'id',
    dir: 'asc',
    term: ''
  }

  private districtsData: PagedResults<District> = {
    items: [],
    total: 0
  }

  public async mounted () {
    await this.getDistricts()
    await this.searchDidChange()
  }

  public async getDistricts () {
    this.loading = true

    try {
      const res = await Api.getDistricts({
        dir: this.tableState.dir,
        skip: this.tableState.perPage * (this.tableState.page - 1),
        sort: this.tableState.sort,
        take: this.tableState.perPage,
        term: this.tableState.term
      })
      if (res) {
        this.districtsData = res
      }
    } catch (error: unknown) {
      this.$buefy.toast.open({
        message: 'Could not load districts. Please try again.',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loading = false
    }
  }

  public async tablePageChanged (page: number) {
    this.tableState.page = page

    await this.searchDidChange()
  }

  public async tableSortChanged (column: string, dir: 'asc' | 'desc') {
    this.tableState.sort = column
    this.tableState.dir = dir

    await this.searchDidChange()
  }

  public async searchDidChange () {
    if (!this.districtsData) {
      return
    }

    await this.getDistricts()

    this.filteredDistrictsData = this.districtsData
  }

  public closeView () {
    this.$emit('close')
  }

  public linkSchool (districtId: number) {
    request('POST', 'districts/' + districtId + '/linkschool', { schoolId: this.schoolId })
      .then((response) => {
        this.$buefy.toast.open({
          message: 'Saved',
          type: 'is-success'
        })
        this.closeView()
      })
      .catch((error) => {
        console.log(error)
        alert(error.message)
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }
}
