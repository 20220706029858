





























import { UnreachableCaseError } from 'ts-essentials'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { SubscriptionPlanPriceTier, SubscriptionPlanPriceType } from '../api'
import { Currency } from '../api/types/currency'
import ComponentHelperBase from '../mixins/ComponentHelperBase'

@Component({
  name: 'PriceTiers'
})
export default class PriceTiers extends Mixins(ComponentHelperBase) {
  @Prop({ required: true }) priceType!: SubscriptionPlanPriceType

  @Prop({ required: true }) tiers!: SubscriptionPlanPriceTier[]

  @Prop({ required: true }) currency!: Currency

  @Prop({ required: false, default: null }) quantity!: number | null

  @Prop({ required: false, default: null }) min!: number | null

  @Prop({ required: false, default: null }) max!: number | null

  @Prop({ required: true }) unitAmount!: number

  @Prop({ required: false, default: 1 }) taxRate!: number

  @Prop({ required: true }) formatCurrency!: (price: number, curr: Currency) => string

  get tableData () {
    if (this.priceType === 'fixed') {
      return [{
        unit_amount: this.unitAmount,
        up_to: this.max ?? 1
      }]
    } else {
      return this.tiers
    }
  }

  textForTier (tier: SubscriptionPlanPriceTier, idx: number) {
    const previousTier = idx === 0 ? undefined : this.tiers[idx - 1]

    const lower = previousTier?.up_to ? (previousTier.up_to + 1) : this.min ?? 1
    const upper = tier.up_to ?? '∞'

    if (this.priceType === 'graduated') {
      if (idx === 0) {
        return `First ${tier.up_to ?? '∞'}`
      } else {
        return `Then ${previousTier!.up_to! + 1} to ${tier.up_to ?? '∞'}`
      }
    } else if (this.priceType === 'volume') {
      if (lower === 1 && upper === this.min) {
        return upper
      }

      return `${lower} to ${upper}`
    } else if (this.priceType === 'fixed') {
      if (!this.min && !this.max) {
        return ''
      } else if (!this.max) {
        return `From ${this.min}`
      } else if (!this.min) {
        return `Up to ${this.max}`
      } else {
        return `Between ${this.min} and ${this.max}`
      }
    } else {
      throw new UnreachableCaseError(this.priceType)
    }
  }

  textForTierPrice (tier: SubscriptionPlanPriceTier, idx: number) {
    // formatCurrency(props.row.unit_amount * taxRate, currency) each
    const previousTier = idx === 0 ? undefined : this.tiers[idx - 1]

    const lower = previousTier?.up_to ?? 1
    const upper = tier.up_to ?? '∞'

    if (this.priceType === 'graduated' || this.priceType === 'volume') {
      if (lower === 1 && upper === this.min) {
        return this.formatCurrency(parseFloat(tier.flat_amount ?? '0') + (upper * parseFloat(tier.unit_amount!)) * this.taxRate, this.currency)
      } else {
        return `${this.formatCurrency(parseFloat(tier.unit_amount!) * this.taxRate, this.currency)} each`
      }
    } else if (this.priceType === 'fixed') {
      throw new Error('fixed not implemented')
    } else {
      throw new UnreachableCaseError(this.priceType)
    }
  }

  textForTierFlatRate (tier: SubscriptionPlanPriceTier, idx: number) {
    // formatCurrency(props.row.unit_amount * taxRate, currency) each
    const previousTier = idx === 0 ? undefined : this.tiers[idx - 1]

    const lower = previousTier?.up_to ?? 1
    const upper = tier.up_to ?? '∞'

    if (this.priceType === 'graduated' || this.priceType === 'volume') {
      if (lower === 1 && upper === this.min) {
        return '' // price will be incorporated in other column
      } else {
        return `${this.formatCurrency(parseFloat(tier.flat_amount!) * this.taxRate, this.currency)} flat rate`
      }
    } else if (this.priceType === 'fixed') {
      throw new Error('fixed not implemented')
    } else {
      throw new UnreachableCaseError(this.priceType)
    }
  }

  tierSuggestsContactingSales (tier: SubscriptionPlanPriceTier, idx: number) {
    const previousTier = idx === 0 ? undefined : this.tiers[idx - 1]

    const lower = previousTier?.up_to ?? 1
    const upper = tier.up_to ?? '∞'

    return !!previousTier && upper === '∞'
  }

  getClassForRow (tier: SubscriptionPlanPriceTier, idx: number) {
    if (this.quantity === null) {
      return undefined
    }

    const previousTier = idx === 0 ? undefined : this.tiers[idx - 1]

    if (this.priceType === 'volume') {
      if ((previousTier?.up_to ?? 0) < this.quantity && (tier.up_to === null || this.quantity <= tier.up_to)) {
        return 'is-success'
      }
    } else if (this.priceType === 'graduated') {
      if ((previousTier?.up_to ?? 0) < this.quantity) {
        if (tier.up_to === null || tier.up_to >= this.quantity) {
          return 'is-success'
        } else {
          return 'is-warning'
        }
      }
    } else if (this.priceType === 'fixed') {
      return 'is-success'
    } else {
      throw new UnreachableCaseError(this.priceType)
    }
  }
}
