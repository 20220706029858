





























































/* eslint-disable vue/require-default-prop */
import { mapState } from 'vuex'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { loadStripe, PaymentMethod, Stripe, StripeCardElement, StripeCardElementChangeEvent, StripeElement, StripeElements } from '@stripe/stripe-js'
import { UnreachableCaseError } from 'ts-essentials'
import { cloneDeep } from 'lodash'
import Coupon from 'stripe'
import { Api, HttpResponseError, request, UserModel } from '@/edshed-common/api'
import config from '@/config'
import ComponentHelper from '@/mixins/ComponentHelper'

export type StripeCardPaymentMeta = StripePayInvoiceMeta | StripeManageCardsMeta | StripeSuperuserCreateFullSubscriptionMeta | StripeSuperuserCreateBackdatedSubscriptionMeta | StripeSuperuserManageCardsMeta

interface StripeSuperuserCreateFullSubscriptionMeta {
  school_id: number
  type: 'superuser.subscription.full'
  base_plan_id: number
  add_on_plan_ids: number[]
  quantity: number
  purchase_order?: string
}

interface StripeSuperuserCreateBackdatedSubscriptionMeta {
  school_id: number
  type: 'superuser.subscription.backdated'
  base_plan_id: number
  add_on_plan_ids: number[]
  quantity: number
  charge_type: 'pay-full' | 'pay-remaining'
  expiry_date: number
  purchase_order?: string
}

interface StripePayInvoiceMeta {
  type: 'invoice.pay'
  invoice_id: string
}

interface StripeManageCardsMeta {
  type: 'manage.cards'
}

interface StripeSuperuserManageCardsMeta {
  type: 'superuser.manage.cards'
  school_id: number
}

@Component({
  name: 'StripeCardPayment',
  computed: {
    ...mapState([
      'stripeToken',
      'stripeTestToken',
      'stripeTokenUS',
      'stripeTestTokenUS'
    ])
  }
})
export default class StripeCardPayment extends Mixins(ComponentHelper) {
  $refs!: {
    card: HTMLElement
  }

  @Prop({ default: false }) disabled!: boolean

  @Prop({ required: true }) zip!: string

  @Prop({ required: true }) meta!: StripeCardPaymentMeta

  @Watch('disabled')
  disabledChanged () {
    if (this.card) {
      this.card.update({ disabled: this.disabled })
    }
  }

  private stripe: Stripe | null = null

  private stripeToken!: string; // is assigned via mapState

  private stripeTestToken!: string; // is assigned via mapState

  private stripeTokenUS!: string; // is assigned via mapState

  private stripeTestTokenUS!: string; // is assigned via mapState

  private card: StripeCardElement | null = null

  private cardValid: boolean = false

  private loadingScreen: any

  private defaultPaymentMethod: PaymentMethod | null = null

  private paymentMethods: PaymentMethod[] = []

  private chosenCard: PaymentMethod | null = null

  @Watch('chosenCard')
  cardSelected (val: PaymentMethod | null) {
    this.$emit('payment-method-selected', val)
  }

  get inProduction () {
    return (process.env.NODE_ENV === 'production')
  }

  get shouldShowPayButton () {
    return this.meta.type !== 'manage.cards' && this.meta.type !== 'superuser.manage.cards'
  }

  async mounted () {
    const isUS = this.$store.state.user ? (this.$store.state.user.school?.account_region === 'US') : this.$i18n.locale === 'en_US'
    const token = (config.serverEnv === 'production' ? (isUS ? this.stripeTokenUS : this.stripeToken) : (isUS ? this.stripeTestTokenUS : this.stripeTestToken))

    // eslint-disable-next-line no-undef
    this.stripe = await loadStripe(token)

    console.log('mounted')
    console.log(this.stripe)

    if (this.stripe) {
      const elements = this.stripe.elements()
      this.card = elements.create('card', { value: { postalCode: this.zip }, hidePostalCode: true, disabled: this.disabled })
      this.card.mount(this.$refs.card)
      this.card.on('change', this.cardDidChange)
      this.getPaymentMethods()
    }
  }

  destroyed () {
    if (this.card) {
      this.card.destroy()
    }
  }

  cardDidChange (event: StripeCardElementChangeEvent) {
    this.cardValid = event.complete
  }

  showLoadingScreen () {
    if (!this.loadingScreen) {
      this.loadingScreen = this.$buefy.loading.open({})
    }
  }

  closeLoadingScreen () {
    if (this.loadingScreen) {
      this.loadingScreen.close()
      this.loadingScreen = null
    }
  }

  setErrorText (text: string) {
    const errorElement = document.getElementById('card-errors')

    if (errorElement) {
      errorElement.innerHTML = text
    }
  }

  async getPaymentMethods () {
    if (!this.$store.state.user || !this.$store.state.user.school) {
      return
    }

    try {
      if (this.$store.state.user.superuser && (this.meta.type === 'superuser.subscription.full' || this.meta.type === 'superuser.subscription.backdated' || this.meta.type === 'superuser.manage.cards')) {
        const response = await Api.getSchoolsPaymentMethods(this.meta.school_id)
        this.paymentMethods = response.payment_methods

        if (response.default_payment_method) {
          this.defaultPaymentMethod = this.paymentMethods.find(m => m.id === response.default_payment_method) ?? null
          this.chosenCard = this.defaultPaymentMethod
        }

        if (this.chosenCard === null && this.paymentMethods.length > 0) {
          this.chosenCard = response.payment_methods[0]
        }
      } else {
        const response = await Api.getMyPaymentMethods()
        this.paymentMethods = response.payment_methods

        if (response.default_payment_method) {
          this.defaultPaymentMethod = this.paymentMethods.find(m => m.id === response.default_payment_method) ?? null
          this.chosenCard = this.defaultPaymentMethod
        }

        if (this.chosenCard === null && this.paymentMethods.length > 0) {
          this.chosenCard = response.payment_methods[0]
        }
      }
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: 'Could not load payment methods',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  async makePayment () {
    this.setErrorText('')

    this.showLoadingScreen()

    if (!this.card) {
      return this.setErrorText('Please enter card details')
    }

    if (!this.cardValid) {
      return this.setErrorText('Your card number is invalid.')
    }

    const paymentMethod = await this.addCard()

    if (paymentMethod) {
      this.paymentMethods.push(paymentMethod)
      this.chosenCard = paymentMethod
      this.makePaymentExisting()
    }
  }

  makePaymentExisting () {
    if (!this.chosenCard) {
      this.$buefy.toast.open({
        message: 'A card must be chosen',
        position: 'is-bottom',
        type: 'is-danger'
      })
      return
    }

    this.showLoadingScreen()

    switch (this.meta.type) {
      case 'invoice.pay':
        this.payInvoice(this.meta, this.chosenCard)
        break
      case 'superuser.subscription.full':
        this.doSuperuserFullSubscription(this.meta, this.chosenCard)
        break
      case 'superuser.subscription.backdated':
        this.doSuperuserBackdatedSubscription(this.meta, this.chosenCard)
        break
      case 'manage.cards':
      case 'superuser.manage.cards':
        throw new Error('This meta type is not for making payments')
      default:
        throw new UnreachableCaseError(this.meta)
    }
  }

  async doSuperuserFullSubscription (meta: StripeSuperuserCreateFullSubscriptionMeta, paymentMethod: PaymentMethod) {
    this.setErrorText('')

    try {
      const response = await Api.superuserAddFullSubscription(meta.school_id, {
        add_on_plan_ids: meta.add_on_plan_ids,
        base_plan_id: meta.base_plan_id,
        quantity: meta.quantity,
        payment_method: paymentMethod.id,
        po_number: meta.purchase_order
      })

      if (response.subscription) {
        this.$emit('subscribed', response.subscription)
      }

      // check for action required
      if (response.intent_secret) {
        const result = await this.stripe!.confirmCardPayment(response.intent_secret)

        this.closeLoadingScreen()
        if (result.error) {
          alert(`Payment failed - ${result.error.message} Please retry payment in the hub or via an alternative method.`)
        } else {
          this.$emit('success')
        }
      } else {
        this.closeLoadingScreen()
        this.$emit('success')
      }
    } catch (err: unknown) {
      if (err instanceof HttpResponseError) {
        this.setErrorText(err.message)
      }

      this.$emit('response', 'Details incorrect')
    } finally {
      this.closeLoadingScreen()
    }
  }

  async doSuperuserBackdatedSubscription (meta: StripeSuperuserCreateBackdatedSubscriptionMeta, paymentMethod: PaymentMethod) {
    this.setErrorText('')

    try {
      const response = await Api.superuserAddBackdatedSubscription(meta.school_id, {
        add_on_plan_ids: meta.add_on_plan_ids,
        base_plan_id: meta.base_plan_id,
        quantity: meta.quantity,
        payment_method: paymentMethod.id,
        charge_type: meta.charge_type,
        expiry_date: meta.expiry_date,
        po_number: meta.purchase_order
      })

      if (response.subscription) {
        this.$emit('subscribed', response.subscription)
      }

      // check for action required
      if (response.intent_secret) {
        const result = await this.stripe!.confirmCardPayment(response.intent_secret)

        this.closeLoadingScreen()
        if (result.error) {
          alert(`Payment failed - ${result.error.message} Please retry payment in the hub or via an alternative method.`)
        } else {
          this.$emit('success')
        }
      } else {
        this.closeLoadingScreen()
        this.$emit('success')
      }
    } catch (err: unknown) {
      if (err instanceof HttpResponseError) {
        this.setErrorText(err.message)
      }

      this.$emit('response', 'Details incorrect')
    } finally {
      this.closeLoadingScreen()
    }
  }

  async payInvoice (meta: StripePayInvoiceMeta, paymentMethod: PaymentMethod) {
    this.setErrorText('')

    try {
      const response = await Api.payStripeInvoice(meta.invoice_id, {
        payment_method_id: paymentMethod.id
      })

      if (response.intent_secret !== null) {
        const result = await this.stripe!.confirmCardPayment(response.intent_secret)

        this.closeLoadingScreen()
        if (result.error) {
          alert(`Payment failed - ${result.error.message} Please retry payment in the hub or via an alternative method.`)
        } else {
          this.$emit('success')
        }

        const that = this
        // The payment has succeeded. Display a success message.
        setTimeout(function () {
          that.$emit('success')
          that.closeLoadingScreen()
        }, 3000)
      } else {
        this.$store.commit('SET_USER', response.user)

        if (window.localStorage) {
          window.localStorage.setItem('user', JSON.stringify(response.user))
        }

        const that = this
        setTimeout(function () {
          that.$emit('success')
          that.closeLoadingScreen()
        }, 3000)
      }
    } catch (err: unknown) {
      if (err instanceof HttpResponseError) {
        this.setErrorText(err.message)
      }

      this.$emit('response', 'Details incorrect')
    } finally {
      this.closeLoadingScreen()
    }
  }

  deletePaymentMethod (method: PaymentMethod) {
    const c = confirm('Delete Card?')
    if (c) {
      request('DELETE', 'users/me/school/paymentmethods/' + method.id, null)
        .then((response) => {
          window.console.log('Response', response)
          this.getPaymentMethods()
        })
        .catch((error) => {
          console.log(error)
          if (error.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/' + this.$i18n.locale + '/logout')
          }
        })
    }
  }

  setDefaultPaymentMethod (method: PaymentMethod) {
    const c = confirm('Set as default payment method?')
    if (c) {
      request('PUT', 'users/me/school/paymentmethods/default', { paymentMethod: method.id })
        .then((response) => {
          window.console.log('Response', response)
          this.getPaymentMethods()
        })
        .catch((error) => {
          console.log(error)
          if (error.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/' + this.$i18n.locale + '/logout')
          }
        })
    }
  }

  async addCard () {
    this.setErrorText('')

    this.showLoadingScreen()

    if (this.stripe && this.card) {
      const result = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card
      })

      if (result.error) {
        // Display result.error.message in UI
        this.closeLoadingScreen()
        this.setErrorText(result.error.message ?? '')
      } else {
        if (this.meta.type === 'manage.cards') {
          this.postNewCard(result.paymentMethod)
        } else if (this.meta.type === 'superuser.manage.cards') {
          this.postNewCardForSchool(this.meta.school_id, result.paymentMethod)
        }

        return result.paymentMethod
      }
    }
  }

  postNewCardForSchool (schoolId: number, paymentMethod: PaymentMethod) {
    request('POST', `superuser/schools/${schoolId}/paymentmethods`, { paymentMethod: paymentMethod.id })
      .then((response) => {
        window.console.log('Response', response)
        this.getPaymentMethods()
        this.closeLoadingScreen()

        if (this.card) {
          this.card.clear()
        }
      })
      .catch((error) => {
        console.log(error)
        if (error.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/' + this.$i18n.locale + '/logout')
        }

        this.closeLoadingScreen()

        this.setErrorText(error.message)
      })
  }

  postNewCard (paymentMethod: PaymentMethod) {
    request('POST', 'users/me/school/paymentmethods', { paymentMethod: paymentMethod.id })
      .then((response) => {
        window.console.log('Response', response)
        this.getPaymentMethods()
        this.closeLoadingScreen()

        if (this.card) {
          this.card.clear()
        }
      })
      .catch((error) => {
        console.log(error)
        if (error.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/' + this.$i18n.locale + '/logout')
        }

        this.closeLoadingScreen()

        this.setErrorText(error.message)
      })
  }
}

